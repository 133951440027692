.swiper-pagination-bullet{
    background-color: #E6E6E6 !important;
    border-radius: 0px;
}
.swiper-pagination-bullet-active{
    background-color: #FF5C01 !important;
    width: 32px;
}

.custom-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-bullet {
    width: 14px;
    height: 14px;
    background-color: #ffffff; /* Custom bullet color */
    margin: 0 5px;
    border-radius: 50%;
    opacity: 0.5;
    transition: opacity 0.3s ease;
}

.custom-bullet.swiper-pagination-bullet {
    background-color: #ffffff; /* Active bullet color */
}

.custom-bullet.swiper-pagination-bullet-active {
    opacity: 1;
    background-color: #FF5C01; /* Active bullet color */
}

.swiper-pagination {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    bottom: 20px;
    left: 3.5%;
    gap: 5%;
}
.swiper-pagination-mobile {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    bottom: 20px;
    left: 3.8% !important;
    gap: 5% !important;
}
